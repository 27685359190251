import { applicationChoiceToMethod } from "@qmspringboard/shared/dist/model/application";
import React, { ComponentType, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { Button, Container, Grid, Header, Menu, Segment, StrictMenuItemProps } from "semantic-ui-react";
import styled from "styled-components";
import useAppDispatch from "../hooks/useAppDispatch";
import useProgrammeList from "../hooks/useProgrammeList";
import { Qualifications } from "../model/applicant";
import { ApplicationChoiceEnum, ApplicationMethodEnum, FeeCategory, OfferStatusEnum } from "../model/enums";
import { indexedErrors, Messages } from "../model/errors";
import { Permissions } from "../model/permission";
import { programmeCodeToUcasCourseCode } from "../model/programme";
import { Team } from "../model/team";
import { Application, ApplicationChoice, Programme, ProgrammeCode } from "../model/types";
import * as applicantActions from "../reducers/applicantActions";
import * as applicantUpdate from "../reducers/applicantUpdate";
import { Opt } from "../utils";
import ApplicationStatusIndicator from "./ApplicationStatusIndicator";
import BaseClearingApplicationEditor from "./ClearingApplicationEditor";
import EditInPlaceForm from "./EditInPlaceForm";
import ErrorDisplay from "./ErrorDisplay";
import BaseUcasApplicationEditor, { ApplicationEditorProps } from "./UcasApplicationEditor";
import { useEmbargoCheck } from "@qmspringboard/app/src/api";

interface MenuProps {
  application: Application;
  editingApplication: Application | null;
  messages: Messages;
  disabled: boolean;
  active: boolean;
  onClick: (evt: MouseEvent) => void;
}

interface MenuItemProps extends StrictMenuItemProps {
  editing: boolean;
}

const Empty = ({ canCreate }: { canCreate: boolean }) => (
  <Segment vertical basic style={{ textAlign: "center", marginBottom: "1.5em" }}>
    <p>This applicant has no offers or applications.</p>
    {canCreate && <p>Click a button to make them an offer!</p>}
  </Segment>
);

const ApplicationTitle = styled.div`
  margin-bottom: 0.25em;
`;
const ApplicationStatusLabel = styled.div`
  margin-top: 0.25em;
`;

const ApplicationDescriptionThinger = ({ application }: { application: Application }) => {
  const { choice, programmeCode, oldProgrammeCode } = application;
  return (
    <div>
      <ApplicationTitle>
        {ApplicationChoiceEnum.labelOf(choice) || choice}, {programmeCode == null ? "???" : programmeCodeToUcasCourseCode(programmeCode)}
        {oldProgrammeCode == null ? null : (
          /* left hand double arrow */
          <small>{` \u21d0 ${programmeCodeToUcasCourseCode(oldProgrammeCode)}`}</small>
        )}
      </ApplicationTitle>
      <ApplicationStatusIndicator statuses={application} />
      <ApplicationStatusLabel>{application.statusLabel}</ApplicationStatusLabel>
    </div>
  );
};

const TabsGridColumn = styled(Grid.Column)`
  padding-right: 0 !important;
  z-index: 1;
`;

const EditorGridColumn = styled(Grid.Column)`
  padding-left: 0 !important;
`;

const ApplicationMenu = styled(Menu)`
  border-right: none !important;
`;

const MenuItemWrapper = ({ editing: _, ...props }: MenuItemProps) => <Menu.Item {...props} />;

const MenuItem = styled(MenuItemWrapper)`
  opacity: ${props => (props.disabled ? 0.3 : 1)} !important;
  border-color: ${props => {
    if (props.active) {
      return props.editing ? "#1578CD" : "rgba(34, 36, 38, 0.15)";
    } else {
      return "transparent";
    }
  }} !important;

  border-right-color: ${props => {
    if (props.active) {
      return props.editing ? "#1578CD" : "white";
    } else {
      return "transparent";
    }
  }} !important;

  background-color: ${props => {
    if (props.active) {
      return props.editing ? "#EAF5FF" : "white";
    } else {
      return "transparent";
    }
  }} !important;
`;

const ApplicationMenuItem = ({ application, messages, editingApplication, ...props }: MenuProps) => {
  return (
    <MenuItem editing={application === editingApplication} {...props}>
      <ApplicationDescriptionThinger application={application} />
      {messages.length > 0 && <ErrorDisplay attached messages={messages} />}
    </MenuItem>
  );
};

function useScriptActions() {
  const dispatch = useAppDispatch();

  const hasApplicationScript = useSelector(applicantUpdate.hasApplicationScript);

  const showApplicationScript = (appn: Application) => dispatch(applicantActions.showApplicationScripts([appn]));

  return { hasApplicationScript, showApplicationScript };
}

function useEmailActions() {
  const dispatch = useAppDispatch();

  const hasApplicationEmail = useSelector(applicantUpdate.hasApplicationEmail);

  const resendApplicationEmail = (appn: Application) => dispatch(applicantUpdate.resendApplicationEmail(appn));

  return { hasApplicationEmail, resendApplicationEmail };
}

function useEditActions() {
  const dispatch = useAppDispatch();

  const anythingEditing = useSelector(applicantUpdate.anythingEditing);
  const editingApplication = useSelector(applicantUpdate.editingApplication);
  const saving = useSelector(applicantUpdate.savingApplication);
  const isDirty = useSelector(applicantUpdate.anythingDirty);

  const onSave = () => dispatch(applicantUpdate.saveApplication());
  const onCancel = () => dispatch(applicantUpdate.cancelEditingApplication());
  const onEdit = () => dispatch(applicantUpdate.editApplication());

  return {
    anythingEditing,
    editingApplication,
    saving,
    isDirty,
    onSave,
    onCancel,
    onEdit,
  };
}

// Returns functions to handle changes of programme.
// These functions only apply if the applicant is already saved.
// If the applicant is unsaved, returns undefined for each function.
function useProgrammeActions(applicantSaved: boolean) {
  const dispatch = useAppDispatch();

  const startProgrammeChange = applicantSaved
    ? (code: ProgrammeCode, interSchool: boolean) => dispatch(applicantUpdate.startProgrammeChange(code, interSchool))
    : undefined;

  const completeProgrammeChange = applicantSaved ? () => dispatch(applicantUpdate.completeProgrammeChange()) : undefined;

  const cancelProgrammeChange = applicantSaved ? () => dispatch(applicantUpdate.cancelProgrammeChange()) : undefined;

  return {
    startProgrammeChange,
    completeProgrammeChange,
    cancelProgrammeChange,
  };
}

interface OuterProps {
  editorTitle: string;
  allProgrammes: Programme[];
  permissions: Permissions;
  applicantSaved: boolean;
  qualifications: Qualifications;
  guessedFeeCategory: FeeCategory | null;
  onChange: (appn: Application) => void;
  readOnly: boolean;
  value: Application;
  initialValue: Opt<Application>;
  canRemove: boolean;
  handleRemove: (appn: Application) => (evt: MouseEvent) => void;
  messages: Messages;
  emailMessages: Messages; // messages pathed as _email_ in the main DTO editor (update page only)
  school: string;
}

function withEditInPlace(Composed: ComponentType<ApplicationEditorProps>): ComponentType<OuterProps> {
  return function WithEditInPlace(props: OuterProps) {
    const {
      editorTitle,
      allProgrammes,
      permissions,
      applicantSaved,
      qualifications,
      guessedFeeCategory,
      onChange,
      readOnly,
      value,
      initialValue,
      canRemove,
      handleRemove,
      messages,
      emailMessages,
      school,
    } = props;

    const { hasApplicationScript, showApplicationScript } = useScriptActions();
    const { hasApplicationEmail, resendApplicationEmail } = useEmailActions();
    const { anythingEditing, editingApplication, saving, isDirty, onSave, onCancel, onEdit } = useEditActions();

    const { startProgrammeChange, cancelProgrammeChange, completeProgrammeChange } = useProgrammeActions(applicantSaved);

    const programme: Programme | undefined = allProgrammes.find(prog => prog.code === value.programmeCode);

    const hasEditPermission: boolean =
      value.id < 0 || (value && programme && permissions.canUpdateApplication(applicationChoiceToMethod(value.choice), programme)) || false;

    const handleShowScriptClick = (evt: MouseEvent) => {
      evt.stopPropagation();
      showApplicationScript(value);
    };

    const handleResendEmailClick = (evt: MouseEvent) => {
      evt.stopPropagation();
      resendApplicationEmail(value);
    };

    return (
      <EditInPlaceForm
        anythingEditing={anythingEditing}
        editing={editingApplication !== null}
        saving={saving}
        readOnly={readOnly || !hasEditPermission}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={onSave}
        isDirty={isDirty}
        messages={messages}
        extraControls={
          <span>
            {hasApplicationScript && <Button onClick={handleShowScriptClick}>Show script</Button>}
            {hasApplicationEmail && hasEditPermission && <Button onClick={handleResendEmailClick}>Resend email</Button>}
          </span>
        }
        style={{ borderTopLeftRadius: 0 }}
      >
        <Composed
          editorTitle={editorTitle}
          allProgrammes={allProgrammes}
          permissions={permissions}
          applicantSaved={applicantSaved}
          qualifications={qualifications}
          guessedFeeCategory={guessedFeeCategory}
          onChange={onChange}
          readOnly={readOnly || !editingApplication}
          value={value}
          initialValue={initialValue}
          canRemove={canRemove}
          handleRemove={handleRemove}
          messages={messages}
          emailMessages={emailMessages}
          startProgrammeChange={startProgrammeChange}
          cancelProgrammeChange={cancelProgrammeChange}
          completeProgrammeChange={completeProgrammeChange}
          school={school}
        />
      </EditInPlaceForm>
    );
  };
}

function withoutEditInPlace(Composed: ComponentType<ApplicationEditorProps>): ComponentType<OuterProps> {
  return function WithoutEditInPlace(props: OuterProps) {
    const {
      editorTitle,
      allProgrammes,
      permissions,
      applicantSaved,
      qualifications,
      guessedFeeCategory,
      onChange,
      readOnly,
      value,
      initialValue,
      canRemove,
      handleRemove,
      messages,
      emailMessages,
      school,
    } = props;

    const { hasApplicationScript, showApplicationScript } = useScriptActions();

    const { hasApplicationEmail, resendApplicationEmail } = useEmailActions();

    const { startProgrammeChange, cancelProgrammeChange, completeProgrammeChange } = useProgrammeActions(applicantSaved);

    const programme: Opt<Programme> = allProgrammes.find(prog => prog.code === value.programmeCode);

    const hasEditPermission: boolean =
      value.id < 0 || (programme != null && permissions.canUpdateApplication(applicationChoiceToMethod(value.choice), programme));

    return (
      <Segment>
        <div
          style={{
            position: "absolute",
            top: "1em",
            right: "1em",
            zIndex: 1,
          }}
        >
          {hasApplicationScript && <Button onClick={() => showApplicationScript(value)}>Show script</Button>}
          {hasApplicationEmail && hasEditPermission && <Button onClick={() => resendApplicationEmail(value)}>Resend email</Button>}
        </div>
        <Container>
          <Composed
            editorTitle={editorTitle}
            allProgrammes={allProgrammes}
            permissions={permissions}
            applicantSaved={applicantSaved}
            qualifications={qualifications}
            guessedFeeCategory={guessedFeeCategory}
            onChange={onChange}
            readOnly={readOnly}
            value={value}
            initialValue={initialValue}
            canRemove={canRemove}
            handleRemove={handleRemove}
            messages={messages}
            emailMessages={emailMessages}
            startProgrammeChange={startProgrammeChange}
            cancelProgrammeChange={cancelProgrammeChange}
            completeProgrammeChange={completeProgrammeChange}
            school={school}
          />
        </Container>
      </Segment>
    );
  };
}

const maybeWithEditControls = (Component: ComponentType<ApplicationEditorProps>) => {
  const WithEditInPlace = withEditInPlace(Component);
  const WithoutEditInPlace = withoutEditInPlace(Component);

  return function EditControls(props: OuterProps) {
    const { applicantSaved, readOnly } = props;
    if (!readOnly && !!applicantSaved) {
      return <WithEditInPlace {...props} />;
    } else {
      return <WithoutEditInPlace {...props} />;
    }
  };
};

const UcasApplicationEditor = maybeWithEditControls(BaseUcasApplicationEditor);

const ClearingApplicationEditor = maybeWithEditControls(BaseClearingApplicationEditor);

const PaddedHeader = styled(Header)`
  padding-top: 5px !important;
`;

export interface ApplicationsEditorProps {
  currentTeam: Team;
  allTeams: Team[];
  permissions: Permissions;
  value: Application[];
  messages: Messages;
  emailMessages: Messages; // messages prefixed with "_email_" in the main editor (update page only)
  applicantSaved: boolean;
  qualifications: Qualifications;
  guessedFeeCategory: FeeCategory | null;
  onAddApplication: (choice: ApplicationChoice) => void;
  onUpdateApplication: (appn: Application) => void;
  onRemoveApplication: (appn: Application) => void;
  canRemoveApplications: boolean;
  anythingEditing: boolean;
  selectedApplication: Application | null;
  selectedApplicationInitial: Application | null;
  selectedApplicationIndex: number | null;
  editingApplication: Application | null;
  setSelectedApplication: (appn: Application) => void;
  readOnly: boolean;
}

function ApplicationsEditor(props: ApplicationsEditorProps) {
  const {
    permissions,
    value,
    messages,
    emailMessages,
    readOnly,
    applicantSaved,
    anythingEditing,
    editingApplication,
    selectedApplication,
    selectedApplicationInitial,
    selectedApplicationIndex,
    setSelectedApplication,
    qualifications,
    guessedFeeCategory,
    onAddApplication,
    onUpdateApplication,
    onRemoveApplication,
    canRemoveApplications,
  } = props;

  const allProgrammes = useProgrammeList();
  const { data: embargoStatus } = useEmbargoCheck();
  const inEmbargoPeriod = embargoStatus ? embargoStatus.isInEmbargoPeriod : false;

  const findProgramme = (appn: Application) => allProgrammes.find(prog => prog.code === appn.programmeCode);

  const handleSelect = (appn: Application) => (_e: MouseEvent) => {
    !anythingEditing && setSelectedApplication(appn);
  };

  const handleAdd = (choice: ApplicationChoice) => (e: MouseEvent) => {
    onAddApplication(choice);
    e.stopPropagation();
  };

  const handleRemove = (appn: Application) => (_e: MouseEvent) => {
    !applicantSaved && selectedApplication && onRemoveApplication && onRemoveApplication(appn);
  };

  const programme = selectedApplication && findProgramme(selectedApplication);
  const programmeSchoolCode = programme && programme.schoolCode;

  const hasApplications = value.length > 0;

  let ApplicationEditor: ComponentType<ApplicationEditorProps> | null;
  switch (selectedApplication && applicationChoiceToMethod(selectedApplication.choice)) {
    case ApplicationMethodEnum.Clearing:
      ApplicationEditor = ClearingApplicationEditor;
      break;
    case ApplicationMethodEnum.Ucas:
      ApplicationEditor = UcasApplicationEditor;
      break;
    default:
      ApplicationEditor = null;
      break;
  }

  // You can only edit an application if:
  // - it is new (no programme)
  // OR
  // - if it's an Invite to Interview (I) application if you have the Invite to interview permission.
  const isInviteToInterview = selectedApplication && selectedApplication.offerStatus === OfferStatusEnum.Interview;

  const canEditSelectedApplication = !programmeSchoolCode || !isInviteToInterview || permissions.canInviteToInterview(programmeSchoolCode);

  const indexErrors = indexedErrors(messages, value.length);
  // const splitErrors = subErrors(messages, { _email_: true });

  const choice = selectedApplication && selectedApplication.choice;

  const editorTitle =
    selectedApplication && choice
      ? selectedApplication.programmeCode
        ? `${ApplicationChoiceEnum.labelOf(choice) || choice} Application to ${programmeCodeToUcasCourseCode(selectedApplication.programmeCode)}`
        : `New ${ApplicationChoiceEnum.labelOf(choice) || choice} Application`
      : "";

  const canCreateClearing: boolean = !readOnly && permissions.canCreateBlankApplication(ApplicationMethodEnum.Clearing, inEmbargoPeriod);

  const canCreateUcas: boolean = !readOnly && permissions.canCreateBlankApplication(ApplicationMethodEnum.Ucas, inEmbargoPeriod);

  const canCreateAnything: boolean = canCreateClearing || canCreateUcas;

  const embargoEndDate = embargoStatus?.embargoPeriod && embargoStatus.isInEmbargoPeriod ? new Date(embargoStatus.embargoPeriod?.endTime) : null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <PaddedHeader>Offers and Applications</PaddedHeader>
        </Grid.Column>
      </Grid.Row>

      {embargoEndDate && !canCreateClearing && (
        <p>
          <b>
            The embargo period is currently underway, which means no new offers can be made. The embargo period will end on{" "}
            {embargoEndDate.toDateString()} at {embargoEndDate.toTimeString()}
          </b>
        </p>
      )}
      {!readOnly && canCreateAnything && (
        <Grid.Row>
          <Grid.Column width={16}>
            {canCreateClearing && (
              <Button content="New Clearing Decision" disabled={anythingEditing} onClick={handleAdd(ApplicationChoiceEnum.Clearing)} />
            )}
            {canCreateUcas && <Button content="New UCAS Application" disabled={anythingEditing} onClick={handleAdd(ApplicationChoiceEnum.First)} />}
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row>
        <Grid.Column width={16}>
          <Grid>
            <Grid.Row>
              {hasApplications && (
                <TabsGridColumn width={4}>
                  <ApplicationMenu fluid vertical tabular>
                    {value.map((application, idx: number) => (
                      <ApplicationMenuItem
                        key={idx}
                        disabled={application !== selectedApplication && anythingEditing}
                        editingApplication={editingApplication}
                        application={application}
                        active={application === selectedApplication}
                        onClick={handleSelect(application)}
                        messages={application === selectedApplication ? [] : indexErrors[idx]}
                      />
                    ))}
                  </ApplicationMenu>
                </TabsGridColumn>
              )}
              <EditorGridColumn stretched width={hasApplications ? 12 : 16}>
                {selectedApplication && selectedApplicationIndex !== null && ApplicationEditor ? (
                  <ApplicationEditor
                    editorTitle={editorTitle}
                    allProgrammes={allProgrammes}
                    readOnly={readOnly || !canEditSelectedApplication}
                    permissions={permissions}
                    messages={indexErrors[selectedApplicationIndex]}
                    emailMessages={emailMessages}
                    applicantSaved={applicantSaved}
                    onChange={onUpdateApplication}
                    value={selectedApplication}
                    initialValue={selectedApplicationInitial}
                    qualifications={qualifications}
                    guessedFeeCategory={guessedFeeCategory}
                    canRemove={canRemoveApplications}
                    handleRemove={handleRemove}
                    school={programmeSchoolCode ?? ""} // HACK: We shouldn't allow null programmeSchoolCodes here
                  />
                ) : (
                  <Empty canCreate={canCreateAnything} />
                )}
              </EditorGridColumn>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default ApplicationsEditor;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { push } from "connected-react-router";
import { schoolToTeamCode } from "../../model/team";
import { Button, Container, Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import { Message, Programme, ProgrammeEditorDTO } from "../../model/types";
import * as programmeUpdate from "../../reducers/programmeUpdate";
import Title from "../../components/Title";
import SubmitButton from "../../components/SubmitButton";
import { unsafeStringToProgrammeCode } from "../../model/programme";
import { Opt } from "../../utils";
import { ProgrammeAuditModal } from "@qmspringboard/app/src/components/ProgrammeAuditModal";
import { show as showModal } from "redux-modal/lib/actions";
import usePermissions from "@qmspringboard/app/src/hooks/usePermissions";
import { ProgrammeEditor } from "./ProgrammeEditor";

interface Params {
  code: string;
}

interface Props {
  match: Match<Params>;
  push: Function;
  fetching: boolean;
  fetched: boolean;
  creating: boolean;
  programmeDTO: Opt<ProgrammeEditorDTO>;
  messages: Message[];
  onInit: () => void;
  onLoad: (code: string) => void;
  onUpdate: (prog: Programme) => void;
  onSave: (prog: ProgrammeEditorDTO) => void;
  onDelete: (code: string) => void;
  showModal: Function;
}

const ProgrammeEditorPage = (props: Props) => {
  const permissions = usePermissions();

  const [readOnly, setReadOnly] = useState(false);

  const unparsedCode = props.match.params.code;
  const { onInit, onLoad, programmeDTO } = props;

  // Replaces both componentDidMount and componentWillReceiveProps for code loading
  useEffect(() => {
    const programmeCode = unsafeStringToProgrammeCode(unparsedCode);
    if (programmeCode) {
      onLoad(programmeCode);
    } else {
      onInit();
    }
  }, [unparsedCode, onLoad, onInit]);

  // Replaces the readOnly state updates from both lifecycle methods
  useEffect(() => {
    const readOnlyValue = programmeDTO == null || !permissions.canUpdateProgrammes(schoolToTeamCode(programmeDTO.programme.schoolCode));
    setReadOnly(readOnlyValue);
  }, [programmeDTO, permissions]);

  const handleDelete = () => {
    const { programmeDTO, onDelete } = props;

    props.showModal("confirm", {
      title: "Delete programme",
      content: "Are you sure?",
      onPrimaryClick: () => programmeDTO && onDelete(programmeDTO.programme.code),
    });
  };

  const handleSave = () => {
    const { programmeDTO, onSave } = props;
    programmeDTO && onSave(programmeDTO);
  };

  const { creating, fetching, onUpdate, messages } = props;
  const hasApplications: boolean = programmeDTO?.hasApplications || false;

  return (
    <Title title={programmeDTO == null || programmeDTO.programme.code === "" ? "New Programme" : programmeDTO.programme.code}>
      <Container>
        <Dimmer.Dimmable as={Container}>
          <Dimmer active={fetching} inverted>
            <Loader>Loading...</Loader>
          </Dimmer>

          {programmeDTO && <ProgrammeEditor programme={programmeDTO.programme} messages={messages} onChange={onUpdate} readOnly={readOnly} />}

          <Divider hidden />
          <Segment basic vertical textAlign="right">
            {programmeDTO && !readOnly && !creating && (
              <>
                {programmeDTO && programmeDTO.programme.code && <ProgrammeAuditModal programmeCode={programmeDTO.programme.code} />}
                {hasApplications && "This programme cannot be deleted as it has applications "}
                <Button color={"red"} onClick={handleDelete} messages={[]} disabled={hasApplications}>
                  Delete
                </Button>
              </>
            )}

            {programmeDTO && !readOnly && (
              <SubmitButton onClick={handleSave} messages={messages}>
                Save
              </SubmitButton>
            )}
          </Segment>
        </Dimmer.Dimmable>
      </Container>
    </Title>
  );
};

export default connect(
  state => ({
    programmeDTO: programmeUpdate.programme(state),
    fetching: programmeUpdate.fetching(state),
    fetched: programmeUpdate.fetched(state),
    creating: programmeUpdate.isNew(state),
    messages: programmeUpdate.messages(state),
  }),
  {
    push,
    onInit: programmeUpdate.initialise,
    onLoad: programmeUpdate.load,
    onUpdate: programmeUpdate.update,
    onDelete: programmeUpdate.del,
    onSave: programmeUpdate.save,
    showModal,
  },
)(ProgrammeEditorPage);
